import React from 'react';
import Image from 'gatsby-image';

import styled from 'styled-components';
import _ from 'lodash';

const BlogAuthorBioContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const ProfileImage = styled(Image)`
  margin-right: 8px;
  margin-bottom: 0;
  min-width: 50px;
  border-radius: 100%;
  border: 1px solid #fff;
  overflow: hidden;
`;

const BlogAuthorBio = ({ author }) => {
  const twitter = _.get(author, 'frontmatter.twitter');
  const picture = _.get(author, 'frontmatter.picture.childImageSharp.fixed');
  const title = _.get(author, 'frontmatter.title');
  const city = _.get(author, 'frontmatter.city');

  return (
    <BlogAuthorBioContainer>
      {picture && <ProfileImage fixed={picture} alt={title} />}
      <p>
        Written by <strong>{title}</strong> who lives and works in {city}{' '}
        building useful things.
        {` `}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://twitter.com/${twitter}`}
        >
          Follow me on Twitter
        </a>
      </p>
    </BlogAuthorBioContainer>
  );
};

export default BlogAuthorBio;
