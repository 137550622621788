import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import _ from 'lodash';

const SocialButton = styled.div`
  vertical-align: top;
  display: inline-block;
  margin-right: 8px;
  text-align: center;
  cursor: pointer;
`;

const BlogPostSharingButtons = ({ location, keywords = [] }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );

  const siteUrl = _.get(site, 'siteMetadata.siteUrl');
  const _siteUrl = `${siteUrl}${location.pathname}`;
  const _keywords = [...keywords, 'nerdia'];

  return (
    <>
      <SocialButton>
        <FacebookShareButton url={_siteUrl} tags={_keywords}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </SocialButton>

      <SocialButton>
        <TwitterShareButton url={_siteUrl} tags={_keywords}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </SocialButton>
    </>
  );
};

export default BlogPostSharingButtons;
