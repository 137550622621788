import React from 'react';
import { Link, graphql } from 'gatsby';
import _ from 'lodash';

import BlogAuthorBio from '../components/BlogAuthorBio';
import BlogLayout from '../components/BlogLayout';
import SeoHelmet from '../components/SeoHelmet';
import styled from 'styled-components';
import BlogPostNav from '../components/BlogPostNav';
import { getLearnPagePath } from '../utils/routes';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import formatReadingTime from '../utils/formatReadingTime';
import BlogPostSharingButtons from '../components/BlogPostSharingButtons';
import BlogLanguageSelector from '../components/BlogLanguageSelector';
import BlogSimulatorPromo from '../components/BlogSimulatorPromo';

const PostTitle = styled.h1`
  margin-top: 12px;
  margin-bottom: 6px;
`;

const PostDate = styled.small`
  margin-bottom: 12px;
  display: block;
`;

const PostSection = styled.section`
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid gray;
`;

const Keyword = styled(Link)`
  text-decoration: none;
  box-shadow: none;
`;

const Keywords = styled.div`
  margin: 4px 0;
  display: inline-block;
`;

const SharingButtons = styled.div`
  display: flex;
  align-items: center;
`;

const SharingButtonsLabel = styled.span`
  margin-right: 4px;
`;

const BlogPostTemplate = ({
  data: { post, author },
  location,
  pageContext: { lang, slug, languages },
}) => {
  if (post == null) {
    return;
  }

  if (author == null) {
    return;
  }
  const keywords = _.get(post, 'frontmatter.keywords', []);

  return (
    <BlogLayout location={location}>
      <SeoHelmet
        location={location}
        title={_.get(post, 'frontmatter.title')}
        description={
          _.get(post, 'frontmatter.description') || _.get(post, 'excerpt')
        }
        keywords={keywords}
        image={_.get(post, 'frontmatter.image.childImageSharp.fixed.src')}
        lang={lang}
      />
      <article>
        <header>
          <PostTitle>{_.get(post, 'frontmatter.title')}</PostTitle>
          <PostDate>
            {_.get(post, 'frontmatter.date')} •{' '}
            {formatReadingTime(_.get(post, 'timeToRead'))}
          </PostDate>
          <BlogLanguageSelector slug={slug} languages={languages} />
        </header>
        <PostSection>
          <MDXRenderer>{_.get(post, 'body')}</MDXRenderer>
          <div>
            Tags:{' '}
            <Keywords>
              {keywords.map(keyword => {
                return (
                  <span key={keyword}>
                    <Keyword to={getLearnPagePath(1, keyword, lang)}>
                      #{keyword}
                    </Keyword>{' '}
                  </span>
                );
              })}
            </Keywords>
          </div>
          <SharingButtons>
            <SharingButtonsLabel>Share:</SharingButtonsLabel>{' '}
            <BlogPostSharingButtons location={location} />
          </SharingButtons>
          <BlogSimulatorPromo />
        </PostSection>

        <footer>
          <BlogAuthorBio author={author} keywords={keywords} />
        </footer>
      </article>

      <BlogPostNav
        prevLocationPathname={_.get(location, `state.prevLocationPathname`)}
      />
    </BlogLayout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostTemplateQuery($id: String!, $author: String!) {
    post: mdx(fields: { id: { eq: $id } }) {
      id
      excerpt(pruneLength: 160)
      body
      timeToRead
      frontmatter {
        author
        title
        date(formatString: "MMMM DD, YYYY")
        description
        keywords
        image {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
    author: mdx(fields: { slug: { eq: $author } }) {
      frontmatter {
        twitter
        picture {
          childImageSharp {
            fixed(width: 50, height: 50) {
              aspectRatio
              src
              srcSet
              width
              height
            }
          }
        }
        title
        city
      }
    }
  }
`;
