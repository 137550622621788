import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { getLearnPath } from '../utils/routes';

const Nav = styled.nav`
  margin: 12px 0;
`;

const NavList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  verticalalign: center;
  margin: 0;
`;

const NavListElement = styled.li`
  margin: 0;
  padding: 0;
`;

const BlogPostNav = ({ prevLocationPathname }) => {
  return (
    <Nav>
      <NavList>
        <NavListElement>
          {Boolean(prevLocationPathname) && (
            <Link to={prevLocationPathname} rel="prev">
              ← Previous Page
            </Link>
          )}
          {!prevLocationPathname && (
            <Link to={getLearnPath()} rel="prev">
              ← Learn more
            </Link>
          )}
        </NavListElement>
      </NavList>
    </Nav>
  );
};

export default BlogPostNav;
